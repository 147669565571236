import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchGroupJoinedDetailApi } from '@/apis/my-info.api';
import type { GroupDetailResponse } from '@/types/my-info/my-info-response.type';

export default defineStore('myInfoStore', () => {
  const groupDetail = ref<GroupDetailResponse>();
  const fetchGroupJoinedDetail = async (groupId: string) => {
    groupDetail.value = await fetchGroupJoinedDetailApi(groupId);
  };
  return {
    fetchGroupJoinedDetail,
    groupDetail
  };
});
